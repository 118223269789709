<template>
    <v-container class="listDocumenti">
        <v-data-iterator
            :items="documenti"
            :items-per-page.sync="itemsPerPage"
            :page="page"
            :search="search"
            :sort-by="sortBy.toLowerCase()"
            :sort-desc="sortDesc"
            hide-default-footer
            no-data-text="Ancora Nessun Documento"
            no-results-text="Nessun Documento"
        >
            <template v-slot:header>
                <v-toolbar
                    color="secondary"
                    class="mb-5"
                >
                    <v-text-field
                        v-model="search"
                        clearable
                        flat
                        solo-inverted
                        hide-details
                        background-color="white"
                        label="Cerca"
                        class="blackTextWriting"
                    >
                        <template v-slot:prepend-inner>
                            <v-icon small>fas fa-search</v-icon>
                        </template>
                    </v-text-field>
                    <v-btn
                        class="ml-2"
                        color="primary"
                        v-if="isMaster && !$vuetify.breakpoint.mdAndUp"
                        fab
                        depressed
                        small
                        to="/newDocumento"
                    >
                        <v-icon small>fas fa-plus</v-icon>
                    </v-btn>
                    <template v-if="$vuetify.breakpoint.mdAndUp">
                        <v-spacer></v-spacer>
                        <v-select
                            v-model="sortBy"
                            flat
                            solo-inverted
                            hide-details
                            background-color="white"
                            :items="keys"
                            label="Ordina Per"
                            class="blackTextWriting"
                        >
                            <template v-slot:prepend-inner>
                                <v-icon small>fas fa-sort</v-icon>
                            </template>
                        </v-select>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-btn-toggle
                            v-model="sortDesc"
                            mandatory
                        >
                            <v-btn
                                large
                                height="40px"
                                depressed
                                color="primary"
                                :value="false"
                            >
                                <v-icon small color="white">fas fa-chevron-up</v-icon>
                            </v-btn>
                            <v-btn
                                large
                                height="40px"
                                depressed
                                color="primary"
                                :value="true"
                            >
                                <v-icon small color="white">fas fa-chevron-down</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                        <v-btn
                            class="ml-2"
                            color="primary"
                            v-if="isMaster"
                            fab
                            depressed
                            small
                            to="/newDocumento"
                        >
                            <v-icon small>fas fa-plus</v-icon>
                        </v-btn>
                    </template>
                </v-toolbar>
            </template>

            <template v-slot:default="props">
                <v-row>
                    <v-col
                        v-for="item in props.items"
                        :key="item._id"
                        cols="12"
                        sm="6"
                        md="4"
                        lg="3"
                    >
                        <v-card :to="'/schedaDocumento?_id='+item._id">
                            <v-card-title class="subheading justify-center">
                                <v-img :src="getImgUrlPreview(item.file)" min-height="90" max-width="60"></v-img>
                            </v-card-title>

                            <v-divider></v-divider>

                            <v-list dense>
                                <v-list-item
                                    v-for="(key, index) in filteredKeys"
                                    :key="index"
                                >
                                    <v-list-item-content :class="sortBy === key ? 'secondary--text bold' : 'bold'">
                                        <small>{{ replaceUnderscore(capitalize(key)) }}</small>
                                    </v-list-item-content>
                                    <v-list-item-content
                                        class="align-end"
                                        :class="{ 'secondary--text bold--text': sortBy === key }"
                                    >
                                        <template v-if="key == 'data_scadenza' || key == 'data_inizio'"><span :class="checkNearDate(item[key.toLowerCase()])">{{ formatDate(item[key.toLowerCase()]) }}</span></template>
                                        <template v-else><div class="trimTextTooLong">{{ item[key.toLowerCase()] }}</div></template>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-col>
                </v-row>
            </template>

            <template v-slot:footer>
                <v-row
                    class="mt-2"
                    align="center"
                    justify="center"
                >

                    <v-spacer></v-spacer>

                    <span
                        class="mr-4
            grey--text"
                    >
            Pagina {{ page }} di {{ numberOfPages }}
          </span>
                    <v-btn
                        fab
                        small
                        color="primary"
                        class="mr-1 white--text"
                        @click="formerPage"
                    >
                        <v-icon small class="white--text">fas fa-chevron-left</v-icon>
                    </v-btn>
                    <v-btn
                        fab
                        small
                        color="primary"
                        class="ml-1"
                        @click="nextPage"
                    >
                        <v-icon small>fas fa-chevron-right</v-icon>
                    </v-btn>
                </v-row>
            </template>
        </v-data-iterator>
    </v-container>
</template>

<script>
import { momentDate } from '@/js/formatDate';
import apiDocumenti from '@/js/pages/documenti';
const FiltriLista = () => import('@/components/FiltriLista');
import { nearDate } from '@/js/formatDate';
import apiUtenti from "@/js/pages/utenti";
import {generalFunction} from "@/js/generalFunction";

export default {
    name: 'listDocumenti',
    components: {
        FiltriLista
    },
    data() {
        return {
            documenti: [],
            token: this.$cookies.get('token'),
            search: '',
            filter: {},
            sortDesc: false,
            page: 1,
            itemsPerPage: 10,
            sortBy: '_id',
            keys: [
                'titolo',
            ],
            siteEndpoint: 'https://app.lacelle.it:5000',
            isMaster: false,
        }
    },
    computed: {
        numberOfPages () {
            return Math.ceil(this.documenti.length / this.itemsPerPage)
        },
        filteredKeys () {
            return this.keys.filter(key => key !== 'Name')
        },
    },
    async created() {
        let token = this.$cookies.get('token');
        if(token) {
            let ruolo = await generalFunction.getRuolo(token);
            if (ruolo) {
                if (ruolo === 'Cliente') {
                    this.isMaster = false;
                    this.documenti = await apiDocumenti.getAllMine(token);
                } else {
                    this.isMaster = true;
                    this.documenti = await apiDocumenti.getAll(token);
                    this.keys.push('data_scadenza');
                }
            }
        }
    },
    methods: {
        capitalize(string) {
            if(string) return string.charAt(0).toUpperCase() + string.slice(1);
            else return '';
        },
        replaceUnderscore(string) {
            if(string) return string.replace(/_/g, " ");
            else return '';
        },
        nextPage () {
            if (this.page + 1 <= this.numberOfPages) this.page += 1
        },
        formerPage () {
            if (this.page - 1 >= 1) this.page -= 1
        },
        updateItemsPerPage (number) {
            this.itemsPerPage = number
        },
        formatDate(date) {
            return momentDate(date);
        },
        getImgUrlPreview(file) {
            if(!file) file = '';
            let fileExt = file.substr(file.lastIndexOf('.') + 1);
            if (fileExt.match(/(csv|mp4|mtl|obj|pdf|qcb|rar|svg|xml|zip)$/i)) {
                var extensions = require.context('@/assets/extensions/', false, /\.png$/);
                if(!extensions) extensions = '';
                return extensions('./' + fileExt + ".png");
            } else if (fileExt.match(/(jpg|jpeg|png|gif|tiff)$/i))
                return 'https://app.lacelle.it:5000' + file;
            else return '';
        },
        checkNearDate(date) {
            let result = nearDate(date);
            if(result === 1) return 'redDateContainer';
            else if(result === 2) return 'yellowDateContainer';
            else return '';
        }
    },
}
</script>
